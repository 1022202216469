import React from 'react';
import Layout from '../components/Layout/layout';
import Jobs from '../Views/Website/Jobs/Jobs';

const JobsPage: React.FC = () => {
  return (
    <Layout>
      <Jobs />
    </Layout>
  );
};

export default JobsPage;
