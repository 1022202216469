import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import * as styles from './style.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { RecruitmentWorkflow } from '../../../Classes/Recruitment';
import { API_GetJobAdverts } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

const Jobs: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [jobs, setJobs] = useState<RecruitmentWorkflow[]>([] as RecruitmentWorkflow[]);

  useEffect(() => {
    GetData();
  }, [])

  const GetData = async () => {
    await Fetch(`${API_GetJobAdverts}`).then((jobList: RecruitmentWorkflow[]) => {
      setJobs(jobList);
    })
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Jobs</title><meta charSet='utf-8' />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[{ To: "", Text: "Jobs" }]} />

      <div className={styles.Documents}>
        <button className={`${styles.FilterButton} `} onClick={() => { }}>
          <i>
            <FontAwesomeIcon icon={faFilter} />
          </i>
          Filter
        </button>

        <div className={styles.DocumentsInner}>
          <div className={styles.Freebies}>
            <div className={styles.FreebiesInner}>
              <h1>Jobs</h1>
              <p>As part of our Recruitment feature within the Portal, we allow Job Listings to be posted by our clients for individuals looking for employment to find them direct from PrimeHR</p>

              <div className={styles.Search}>
                <div className={styles.SearchInput}>
                  <i><FontAwesomeIcon icon={faSearch} /></i>
                  <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
              </div>

              <div className={styles.Articles}>
                <div className={styles.ArticlesInner}>
                  {
                    jobs.length ? <>{
                      jobs.filter((j) => j.JobTitle.toLowerCase().includes(search.toLowerCase())).map((job: RecruitmentWorkflow) => {
                        return <Link to={`/Job?ID=${window.btoa(job.ID.toString())}`} className={styles.Freebie}>
                          <div className={styles.FreebieInner}>
                            <h2>{job.JobTitle}</h2>
                            <h3>£{job.SalaryMin} - £{job.SalaryMax}</h3>
                            <p>{job.Type}, {job.Hours} Hours Per Week, {job.Department}</p>
                          </div>
                          <i>
                            <FontAwesomeIcon icon={faArrowRight} />
                          </i>
                        </Link>
                      })
                    }</> : <div className={styles.Message}>
                      <i>
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </i>
                      <h1>No Jobs Available</h1>
                      <p>There are no available job roles to be applied for at the moment. Check back later for updates!</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobs;
